.two_images{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .two_images__image{
        font-size: 14px;
        width: ~"calc(50% - 10px)";
        height: auto;
        margin-bottom: 20px;
        &:nth-child(odd){
            margin-right: 10px;
        }
        &:nth-child(even){
            margin-left: 10px;
        }
        img{
            width: 100%;
            height: auto;
        }
        @media(max-width: @screen-xs-max){
            width: 100%;
            &:nth-child(odd){
                margin-right: 0px;
            }
            &:nth-child(even){
                margin-left: 0px;
            }
        }
    }
}