.home{
    .leftcolumn{
        @media(max-width: @screen-xs-max){
            padding: 0;
        }
        .leftcolumn__content{
            @media(max-width: @screen-xs-max){
                display: none;
            }
        }
    }
    .rightcolumn{
        padding-top: 1px;
        @media(max-width: @screen-xs-max){
            padding-top: 75px;
        }
    }
}