.landscape-images{
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    
    .landscape-images__image{
        display: block;
        position: relative;
        width: ~"calc(50% - 10px)";
        height: auto;
        margin-bottom: 20px;
        &:nth-child(odd){
            margin-right: 10px;
        }
        &:nth-child(even){
            margin-left: 10px;
        }
        img{
            width: 100%;
            height: auto;
        }
        @media(max-width: @screen-sm-max){
            width: 100%;
            &:nth-child(odd){
                margin-right: 0;
            }
            &:nth-child(even){
                margin-left: 0;
            }
        }
    }
}