// Wysiwyg styles
a{
    color: @darkgrey;
    &:hover, &:focus{
        color: @darkgrey;
        text-decoration: none;
    }
}

h1{
    font-size: 45px;
    line-height: 54px;
    letter-spacing: -0.02em;
    @media(max-width: @screen-sm-max){
        font-size: 37px;
    }
    @media(max-width: @screen-xs-max){
        font-size: 40px;
    }
}
h2{
    font-size: 30px;
    font-weight: 500;
    @media(max-width: @screen-sm-max){
        font-size: 25px;
    }
    @media(max-width: @screen-xs-max){
        font-size: 30px;
    }
}
p{
    font-size: 18px;
    line-height: 23px;
    @media(max-width: @screen-xs-max){
        font-size: 20px;
    }
}