html {
	-webkit-font-smoothing: antialiased;
}

body, #tinymce{
	color: black;
	.font();
	font-size: 18px;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
}

.frame{
	position: relative;
	width: 100vw;
	height: 100vh;
	padding: 0 150px 0 100px;
	@media(max-width: @screen-xs-max){
		height: auto;
		width: auto;
		padding: 0;
	}
}

@footer: 112px;
.framewrapper{
	display: flex;
	height: 100vh;
	width: 100%;
	padding: 0 150px 0 100px;
	@media(max-width: 1450px){
		padding: 0 75px 0 50px;
	}
	@media(max-width: @screen-sm-max){
		padding: 0 70px 0 30px;
	}
	@media(max-width: @screen-xs-max){
		flex-wrap: wrap;
		height: auto;
		width: auto;
		padding: 0 45px 0 30px;
		overflow-y: visible;
	}
	.framewrapper__left{
		width: 34%;
		padding: 115px 0 50px 0;
		@media(max-width: @screen-sm-max){
			width: 40%;
		}
		@media(max-width: @screen-xs-max){
			padding: 95px 0 50px 0;
			width: 100%;
		}
	}
	.framewrapper__right{
		width: 66%;
		@media(max-width: @screen-sm-max){
			width: 60%;
		}
		@media(max-width: @screen-xs-max){
			width: 100%;
			padding-bottom: 50px;
		}
	}
}
.framewrapper--scrollstop{
	overflow: hidden;
}

.leftcolumn{
	.leftcolumn__content{
		position: fixed;
		width: 26%;
		height: 100%;
		margin-right: 40px;
		@media(max-width: @screen-sm-max){
			width: 40%;
		}
		@media(max-width: @screen-xs-max){
			position: relative;
			width: 100%;
		}
	}
	.leftcolumn__group{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		visibility: hidden;
		opacity: 0;
		overflow-y: auto;
		height: 85%;
		padding-right: 10px;
		padding-bottom: 100px;
		transform: translateY(10px);
		transition: transform 0.4s ease, opacity 0s ease;
		z-index: 50;
		h1{
			margin-top: 1px;
			margin-bottom: 60px;
			@media(max-width: @screen-sm-max){
				margin-bottom: 50px;
			}
			@media(max-width: @screen-xs-max){
				margin-bottom: 20px;
			}
		}
		@media(max-width: @screen-sm-max){
			right: 80px;
			-webkit-overflow-scrolling: touch;
		}
		@media(max-width: @screen-xs-max){
			position: relative;
			width: 100%;
			overflow-y: visible;
			padding-bottom: 0;
		}
	}
	.active-item{
		opacity: 1;
		visibility: visible;
		transform: translateY(0);
		transition: transform 1s ease, opacity 1s ease;
	}
}

.paragraph{
	.paragraph--collapsed{
		display: none;
	}
	.icon-arrow{
		cursor: pointer;
		&[aria-expanded="true"]{
			transform: rotate(180deg);
		}
	}
}

.rightcolumn{
	>div:last-child{
		padding-bottom: 90px;
		@media(max-width: @screen-xs-max){
			padding-bottom: 25px;
		}
	}
	.right_column__group{
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 100px;
		//min-height: 100vh;
		@media(max-width: @screen-xs-max){
			min-height: auto;
			padding-bottom: 45px;
		}
	}
	.rightcolumn__title{
		padding-top: 10px;
		padding-bottom: 50px;
		padding-right: 45px;
		@media(max-width: @screen-xs-max){
			display: none;
		}
	}
	.rightcolumn__intro{
		padding-bottom: 30px;
		@media(min-width: @screen-sm-min){
			display: none;
		}
	}
	.has_title{
		align-items: flex-start;
		flex-direction: column;
		justify-content: flex-start;
	}
}
// .page-template-template-projects{
// 	.rightcolumn{
// 		padding-top: @footer;
// 	}
// }

