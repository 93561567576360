.single-project{
    .swipelink{
        cursor: pointer;
    }
    .caption{
        display: block;
        font-size: 14px;
        margin-top: 10px;
    }
}
