.carousel{
    width: 100%;
    .swiper-container,
    .swiper-book{
        width: 100%;
        height: 70vh;
        @media(max-width: @screen-sm-max){
            height: auto;
        }
    }
    img{
        .object-fit(cover,center);
        height: 100%;
        width: 100%;
    }
    .navigation{
        position: absolute;
        width: 50%;
        height: 100%;
        z-index: 50;
    }
    .next{
        right: 0;
        top: 0;
        &:hover{
            cursor:e-resize;
        }
    }
    .prev{
        left: 0;
        top: 0;
        &:hover{
            cursor:w-resize;
        }
    }
    .carousel__counter{
        font-size: 14px;
        margin-top: 20px;
    }
}

.swiper-scrollbar {
    border-radius: 10px;
    position: relative;
    -ms-touch-action: none;
    background: rgba(0,0,0,0.1);
    .swiper-container-horizontal > & {
        position: absolute;
        left: 1%;
        bottom: 3px;
        z-index: 600;
        height: 5px;
        width: 98%;
    }
    .swiper-container-vertical > & {
        position: absolute;
        right: 3px;
        top: 1%;
        z-index: 600;
        width: 5px;
        height: 98%;
    }
}
.swiper-scrollbar-drag {
    height: 100%;
    width: 100%;
    position: relative;
    background: rgba(0,0,0,0.5);
    border-radius: 10px;
    left: 0;
    top: 0;
    cursor: pointer;
}
.swiper-scrollbar-cursor-drag {
    cursor: move;
}