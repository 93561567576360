.site-footer{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0 150px 0 100px;
    z-index: 100;
     @media(max-width: 1450px){
        padding: 0 75px 0 50px;
    }
    @media(max-width: @screen-md-max){
		padding: 0 75px 0 30px;
	}
    .menu{
        width: 34%;
        .reset-list();
        background: white;
        padding-top: 25px;
        padding-bottom: 25px;
        padding-right: 45px;
        display: flex;
        justify-content: space-between;       
        @media(max-width: 1024px){
            width: 35%;
            padding-right: 25px;
        }
        @media(max-width: @screen-sm-max){
            width: 40%;
        }
        @media(max-width: @screen-xs-max){
            position: fixed;
            left: 0;
            bottom: 0;
            width: 100%;
            padding: 10px 30px;
		}
        li{
            display: inline-block;
            font-size: 30px;
            font-weight: 500;
            letter-spacing: -0.02em;
            @media(max-width: @screen-md-max){
                font-size: 24px;
            }
            @media(max-width: @screen-sm-max){
                font-size: 20px;
            }
            a{
                color: black;
            }
        }
        .active{
            a{
                color: @darkgrey;
            }
        }
    }
}