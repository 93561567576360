.projects{
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.project{
    display: block;
    position: relative;
    width: ~"calc(50% - 10px)";
    height: auto;
    margin-bottom: 20px;
    &:nth-child(odd){
        margin-right: 10px;
    }
    &:nth-child(even){
        margin-left: 10px;
    }
    @media(max-width: @screen-md-max){
        width: 100%;
        &:nth-child(odd){
            margin-right: 0;
        }
        &:nth-child(even){
            margin-left: 0;
        }
    }
    &:hover {
        cursor: pointer;
        .project__overlay{
            opacity: 1;
            transition: opacity 0.4s ease;
            div{
                // transform: translateY(0);
                // transition: transform 0.6s ease-out 0.2s;
            }
        }
    }
    img{
        //.object-fit(cover, center);
        height: auto;
        width: 100%;
    }
    .project__overlay{
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        padding: 30px;
        color: black;
        background: @grey;
        opacity: 0;
        transition: opacity 0.4s ease;
        @media(max-width: @screen-sm-max){
            position: relative;
            opacity: 1;
            background: transparent;
            display: block;
            padding: 0;
        }
        div{
            // transform: translateY(3px);
            // transition: transform 0.6s linear 0s;
        }
        h2{
            margin: 0;
            @media(max-width: @screen-sm-max){
                margin-top: 20px;
            }
        }
        .price{
            position: absolute;
            right: 30px;
            bottom: 30px;
        }
    }
}