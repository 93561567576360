.portrait-images{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    .portrait-images__image{
        width: ~"calc(33.33% - 15px)";
        height: auto;
        margin-bottom: 20px;
        @media(max-width: @screen-md-max){
            width: ~"calc(50% - 10px)";
            height: auto;
            margin-bottom: 20px;
            &:nth-child(odd){
                margin-right: 10px;
            }
            &:nth-child(even){
                margin-left: 10px;
            }
        }
        img{
            height: auto;
            width: 100%;
        }
    }
}