@import "~bootstrap/less/bootstrap.less";
@import "~swiper/dist/css/swiper.min.css";

// Common
@import "common/_variables.less";
@import "common/_mixins.less";
@import "common/_global.less";

// Typography
@import "common/_typography.less";
@import "common/_wysiwyg.less";

// Components
@import "components/_header.less";
@import "components/_projects.less";
@import "components/_portrait-images.less";
@import "components/_landscape-images.less";
@import "components/_carousel.less";
@import "components/_image.less";
@import "components/_footer.less";
@import "components/_quote.less";
@import "components/_fullscreen-carousel.less";
@import "components/_two_images.less";

// Layouts
@import "layouts/_single-project.less";
@import "layouts/_frontpage.less";
@import "layouts/_single-book.less";