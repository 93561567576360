.quote{
    padding: 120px 0;
    @media(max-width: @screen-sm-max){
        padding: 75px 0;
    }
    h2{
        margin: 0;
        font-size: 45px;
        line-height: 54px;
        letter-spacing: -0.02em;
    }
    span{
        display: block;
        font-size: 30px;
        letter-spacing: -0.02em;
    }
}