// Mixins

.reset-list() {
	list-style: none;
	padding: 0;
	margin: 0;
}

.overflow-elipsis() {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

/*
  This mixin can be used to set the object-fit:
  .object-fit(contain);
  or object-fit and object-position:
  .object-fit(cover, top);
*/
.object-fit(@fit: fill, @position) {
  -o-object-fit: @fit;
     object-fit: @fit;
  -o-object-position: @position;
     object-position: @position;
  font-family: 'object-fit: @{fit}; object-position: @{position}';
}

.object-fit(@fit: fill){
  -o-object-fit: @fit;
     object-fit: @fit;
  font-family: 'object-fit: @{fit}';
}

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}