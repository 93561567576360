.fullscreen-carousel{
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s ease 0s, visibility 0.1s linear 0.4s;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 20px 0 90px 0;
    background: white;
    z-index: 500;
    .fullscreenswiper-container{

        height: 100%;
        .swiper-slide{
            position: relative;
            padding: 0 150px 0 100px;
            @media(max-width: @screen-sm-max){
                padding: 0 30px;
            }
        }
        .swiper-image{
            position: relative;
            display: inline-block;
            height: 100%;

        }
        img{
            position: relative;
            .object-fit(contain, left);
            height: 100%;
            width: auto;
            max-width: 100%;
            z-index: 0;
        }
    }
    .fullscreen-navigation{
        position: fixed;
        top: 0;
        left: 100px;
        right: 150px;
        bottom: 0;
        z-index: 50;
        @media(max-width: @screen-sm-max){
            left: 30px;
            right: 30px;
        }
        .navigation{
            position: absolute;
            top: 0;
            height: 100%;
        }
        .next{
            right: 0;
            width: 78%;
            &:hover{
                cursor: e-resize;
            }
        }
        .prev{
            left: 0;
            width: 22%;
            &:hover{
                cursor: w-resize;
            }
        }
    }
    .carouseldata{
        font-size: 14px;
        margin-top: 20px;
        padding: 0 150px 0 100px;
        @media(max-width: @screen-sm-max){
            padding: 0 30px;
        }
        .carouseldata__counter,
        .carouseldata__caption{
            display: inline-block;
        }
        .carouseldata__caption{
            margin-left: 30px;
        }
    }
}

.fullscreen-carousel--visible{
    .header{
        display: none;
    }
    .fullscreen-carousel{
        opacity: 1;
        visibility: visible;
        transition: opacity 0.4s ease 0.1s, visibility 0.1s linear 0s;
    }
}

.fullscreen-exit{
    position: fixed;
    right: 100px;
    top: 20px;
    cursor: pointer;
    z-index: 100;
    @media(max-width: @screen-sm-max){
        right: 30px;
    }
}

