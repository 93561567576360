.header{
    position: fixed ;
    top: 25px;
    left: 100px;
    right: 100px;
    font-size: 30px;
    font-weight: 500;
    letter-spacing: -0.02em;
    z-index: 500;
    @media(max-width: 1450px){
        right: 25px;
        left: 50px;
    }
    @media(max-width: 1025px){
        font-size: 25px;
    }
    @media(max-width: @screen-sm-max){
        left: 30px;
    }
    @media(max-width: @screen-xs-max){
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        padding: 25px 45px 10px 30px;
        background: white;
    }
    a{
        color: black;
    }
    .langswitcher{
        position: absolute;
        right: 0;
        top: 0;
        text-transform: uppercase;
        @media(max-width: @screen-xs-max){
            top: 25px;
            right: 45px;
        }
    }
}